<template>
  <ButtonStock class="textCenter w100 mb-10" title="Библиотека документов" @click="goToLibrary" />
  <ButtonStock class="textCenter w100 mb-10" title="Мониторинг СМИ" @click="goToMedia" />
</template>

<script>
  import ButtonStock from '@/components/buttons/ButtonStock.vue';

  export default {
    name: 'Widget13',
    components: {
      ButtonStock,
    },
    methods: {
      goToLibrary() {
        this.$router.push({ name: 'Library' });
      },
      goToMedia() {
        this.$router.push({ name: 'MassMediaCard', params: { id: 0, action: 'edit' } });
      },
    },
    data() {
      return {};
    },
    created() {},
  };
</script>

<style lang="scss" scoped></style>
